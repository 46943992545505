import React from 'react'

import '../styles/trust.css'
const Trust = () => {
  return (
    <>
      <div className="trust-flcc">
        <div className="trust-flcc-cont">
          <h2 className='afted'>We carry your trust.</h2>
          <h4>With a global network and advanced logistics solutions, our air freight services provide.</h4>
        </div>
      </div>
    </>
  )
}

export default Trust