import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./App.css";
import Home from "./components/home/templates/Home";
import Header from "./components/header/templates/Header";
import Footer from "./components/footer/templates/Footer";
import Support from "./components/support/templates/Support";
import TextStroke from "./components/animations/TextStroke/TextStroke";
import Dashboard from "./components/Dashboard/templates/Dashboard";
import ApplicationSent from "./components/Dashboard/templates/ApplicationSent";
import Request from "./components/request/templates/Request";

import { fetchArtists } from "./components/database/actions/artist";
import LiveChat from "./components/livechat/templates/LiveChat";
import Auth from "./components/auth/templates/Auth";

const App = () => {
  const [liveC, setLiveC] = useState(false);
  const dispatch = useDispatch();
  const [preLoader, setPreLoader] = useState(false)

  useEffect(() => {
    dispatch(fetchArtists());
  }, [dispatch]);

  useEffect(() => {
    setPreLoader(true)
    setTimeout(() => {
      setPreLoader(false)
    }, 4000)
  }, [])

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <BrowserRouter>

      {
        preLoader ? <TextStroke /> :
          <>
            <div className="wrapper">
            <div id="google_translate_element"></div>
              <Header liveC={liveC} setLiveC={setLiveC}/>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/track-shipment" element={<Dashboard />} />
                <Route exact path="/track-shipment/:bol" element={<Dashboard />} />
                <Route exact path="/request-quote/applied" element={<ApplicationSent />} />
                <Route exact path="/request-quote/" element={<Request />} />
                <Route exact path="/support" element={<Support />} />
                <Route exact path="/admin/liveChat" element={<LiveChat />} />
                <Route exact path="/authenticate" element={<Auth />} />
              </Routes>
              <Footer />
            </div>
          </>
      }
    </BrowserRouter>
  );
};

export default App;
