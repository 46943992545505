import axios from 'axios';

const API = axios.create({ baseURL: 'https://twosecscourier.onrender.com' });

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')){
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
})

export const fetchAccounts = () => API.get('/user');
export const fetchAccount = (id) => API.get(`/user/${id}`);

export const msg = (msgDets, userID) => API.post(`/user/sendMsg/${userID}`, {msgDets});
export const updateMsgCounterSupport = (current, userID) => API.patch(`/user/${userID}/updateMsgCounterSupport`, {current});

export const signIn = (formData) => API.post('/auth/signin', formData);
export const signUp = (formData) => API.post('/auth/signup', formData);