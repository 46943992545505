import React from 'react'
import { Link } from 'react-router-dom'

import '../styles/about.css'

import { BsCheck } from 'react-icons/bs'
const About = () => {
  return (
    <>
      <div className="about-flcc">
        <div className="about-flcc-cont">
          <div className="about-intro">
            <h1 className='afted'>Our People, Your Freight</h1>
            <h4>QShip USA has a proven methodology for engaging customers using a collaborative solution development process to understand business needs.</h4>
            <h4>To tailor our solution to specifically deliver the objectives for our customer's global supply chains.</h4>
            <Link to={`/`}>Read more about us</Link>
          </div>
          <div className="about-group">
            <div className="sample-video">
              <iframe src="https://www.youtube.com/embed/KEFt2quibkg" title="YouTube video player" allowFullScreen></iframe>
            </div>
            <div className="core-values">
              <div className="core-image"></div>
              <div className="core-list">
                <h3 className='afted'>Our Core Values</h3>
                <ul>
                  <li><BsCheck /><p>Innovative - Open and creative to customer and employee solutions.</p></li>
                  <li><BsCheck /><p>Transparent Communication and Collaboration. We communicate openly.</p></li>
                  <li><BsCheck /><p>Don't fix what isn't broken, unless it provides a road map to increased productivity.</p></li>
                  <li><BsCheck /><p>Shared Goal & Initiative Alignment.We accomplish our goals more efficiently.</p></li>
                  <li><BsCheck /><p>Our proactive approach to invoice consolidation and freight analysis reports.</p></li>
                </ul>
                <Link to={`/`}>expertise & skills</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About