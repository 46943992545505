import React from 'react'

import '../styles/brands.css'

import { GiOilDrum } from 'react-icons/gi'
import { IoIosCompass } from 'react-icons/io'
import { MdHotelClass } from 'react-icons/md'
import { CiVault } from 'react-icons/ci'
import { SiCommerzbank, SiSteamdb } from 'react-icons/si'
const Brands = () => {
  return (
    <>
      <div className="brands">
        <ul>
          <li><GiOilDrum /><h3>QShip <br /> Oil & Gas</h3></li>
          <li><IoIosCompass /><h3>QShip <br />Travels</h3></li>
          <li><MdHotelClass /><h3>QShip <br />Hotels</h3></li>
          <li><SiSteamdb /><h3>QShip <br />Groups</h3></li>
        </ul>
      </div>
    </>
  )
}

export default Brands