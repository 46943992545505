import React from 'react'
import { Link } from "react-router-dom";

import { GiCargoShip } from "react-icons/gi";
const DesktopNav = () => {
  return (
    <>
      <header className="headed laptop">
        <div className="lap-nav">
          <div className="logo"><Link to="/"><h2><GiCargoShip /></h2><h4>QShip <span>USA</span></h4></Link><h5>Courier & Cargo</h5></div>
          <ul className='main-menu'>
            <li><Link to={`/home-modern`}> Home Modern </Link></li>
            <li><Link to={`/track-shipment`}> Track Your Shipment </Link></li>
            <li><Link to={`/company`}> Company </Link></li>
            <li><Link to={`/consumer-goods`}> Consumer Goods </Link></li>
            <li><Link to={`/our-expertise`}> Our Expertise </Link></li>
            <li><Link to={`/partners`}> Patners </Link></li>
            <li><Link to={`/contact-us`}> Contact US </Link></li>
          </ul>
          <ul className='side-menu'>
            <div className="left-side-menu">
              <li><Link to={`/news`}> News & Media </Link></li>
              <li><Link to={`/careers`}> Careers </Link></li>
              <li><Link to={`/contacts`}> contacts </Link></li>
            </div>
            <Link to={`/request-quote`}><h5> Get free Quote </h5></Link>
          </ul>
        </div>

      </header>
    </>
  )
}

export default DesktopNav