import React from 'react'
import { Link } from 'react-router-dom';


import { FiFacebook, FiTwitter } from "react-icons/fi";
import { TbBrandTelegram, TbBrandInstagram } from "react-icons/tb";
import { FaTiktok } from 'react-icons/fa'

import '../styles/socials.css'

const Socials = () => {
  return (
    <>
      <div className="social-handles">
        <div className="social cont">
          <div className="socials-left">
            <h2>Delivered on time with no hassle.</h2>
            <ul>
              <li><Link to='https://facebook.com'><FiFacebook /> </Link></li>
              <li><Link to='https://instagram.com'><TbBrandInstagram /></Link></li>
              <li><Link to='https://tiktok.com'><FaTiktok /></Link></li>
              <li><Link to='https://t.me'><TbBrandTelegram /></Link></li>
              <li><Link to='https://x.com'><FiTwitter /></Link></li>
            </ul>
          </div>
          <div className="socials-right">
            <h4>KEEP UP TO DATE:</h4>
            <form action="#" className='filler'>
              <input type="email" name="email" id="email"  required />
              <button type="submit">Sign Me Up!</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Socials