import React from 'react'
import { Link } from 'react-router-dom'

const Copyright = () => {
  return (
    <>
    <div className="copyrights">
        <div className="copyright-cont">
            <div className="cpr">
                <h5>© 2023 QShip USA <span> All rights reserved</span></h5>
                <ul>
                    <li><Link to={`/`}>Privacy</Link></li>
                    <li><Link to={`/`}>Terms & Condition</Link></li>
                    <li><Link to={`/support`}>Support</Link></li>
                    <li><Link to={`/`}>Contact</Link></li>
                </ul>
            </div>
        </div>
    </div>
    </>
  )
}

export default Copyright