import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'

const Tracker = () => {
  const initialState = {  bol: ''}
  const [formData, setFormData] = useState(initialState);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    navigate(`/track-shipment/${formData.bol}`)
  }
  return (
    <>
      <div className="track-trace">
        <div className="tt-cont">
          <div className="track-info">
            <h3>Tracking shipments with QShip </h3>
            <ul>
              <li>Depending on the tracking technology that carriers are using, QShip's system will work differently to receive and create updates on the status of the shipments. While some tracking updates are received through automated systems, others require QShip tracking teams to regularly reach out to carriers. If you have questions about tracking your shipment, contact your Account Executive.</li>
              <li>Make sure you use a QShip BOL, otherwise it could result in tracking information delays. The carriers send shipment tracking information to QShip's system when a QShip BOL is used.</li>
              <li>For help finding the BOL number, take a look at a BOL example. Contact your account manager to get a copy of your BOL if you don't have one.</li>
            </ul>
            <form onSubmit={handleSubmit} className='filler'>
                <input type="text" name="bol" id="bol" onChange={handleChange} required />
              <button type="submit">Track & Trace</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Tracker