import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import '../styles/dashboard.css'

import { MdVerified, MdNearbyError } from 'react-icons/md'
import { GoUnverified } from 'react-icons/go'
import { BsThreeDots } from 'react-icons/bs'
import { TbTruckDelivery } from 'react-icons/tb'


import { fetchArtist } from '../../database/actions/artist'
import Loaded from './Loaded'

const Dashboard = () => {
  const initialState = { bol: '' }
  const { artist, isLoading } = useSelector((state) => state.artists);
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { bol } = useParams()

  const [stationA, setStationA] = useState('')
  const [stationB, setStationB] = useState('')
  const [stationC, setStationC] = useState('')
  const [stationD, setStationD] = useState('')
  const [stationE, setStationE] = useState('')
  const [stationF, setStationF] = useState('')
  const [last, setLast] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    if (bol) {
      dispatch(fetchArtist(bol, setError))
    }
  }, [bol])

  console.log(artist);

  useEffect(() => {
    if (artist && artist?.stations[0]?.status === 'Arrived') {
      setStationA('ar-rived')
    } else if (artist && artist?.stations[0]?.status === 'On its Way') {
      setStationA('on-way')
    } else {
      setStationA('')
    };

    if (artist && artist?.stations[1]?.status === 'Arrived') {
      setStationB('ar-rived')
    } else if (artist && artist?.stations[1]?.status === 'On its Way') {
      setStationB('on-way')
    } else {
      setStationB('')
    };

    if (artist && artist?.stations[2]?.status === 'Arrived') {
      setStationC('ar-rived')
    } else if (artist && artist?.stations[2]?.status === 'On its Way') {
      setStationC('on-way')
    } else {
      setStationC('')
    };

    if (artist && artist?.stations[3]?.status === 'Arrived') {
      setStationD('ar-rived')
    } else if (artist && artist?.stations[3]?.status === 'On its Way') {
      setStationD('on-way')
    } else {
      setStationD('')
    };

    if (artist && artist?.stations[4]?.status === 'Arrived') {
      setStationE('ar-rived')
    } else if (artist && artist?.stations[4]?.status === 'On its Way') {
      setStationE('on-way')
    } else {
      setStationE('')
    };
  }, [artist]);

  const handleChange = (e) => {
    setFormData({ [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    navigate(`/track-shipment/${formData.bol}`)
  }

  useEffect(() => {
    if (artist) {
      setLast(artist.stations[artist.stations.length - 1])
    }

    if (last && last?.status === 'Arrived') {
      setStationF('ar-rived')
    } else if (last && last?.status === 'On its Way') {
      setStationF('on-way')
    } else {
      setStationF('')
    };

    console.log(artist)
    console.log(last)
  }, [artist, last])

  if (isLoading) {
    return (
      <Loaded />
    )
  }

  if (error || !artist) {
    return (
      <div className="error-box">
        <MdNearbyError />
        <h2> "No Item(s) Found:" <span> Try a Different Query </span></h2>
        <form onSubmit={handleSubmit} className="filler">
          <input type="text" name='bol' id='bol' placeholder='Enter BOL' onChange={handleChange} required />
          <button type='submit'>Track</button>
        </form>
      </div>
    )
  }

  return (
    <>
      <div className="dashboard mh1">
        <div className="dashboard-cont">
          <div className="headt">
            <h2>#BOL: <span>{bol}</span> Status</h2>
            {
              (artist.errorMsg === '') ? " " :
              <div className="error-handler">
                <h3>{artist?.errorMsg}</h3>
              </div>
            }
          </div>
          {
            artist &&
            <div className="heat-map">
              <h3><TbTruckDelivery />Stations</h3>
              <ul>
                <li className={`origin ${stationA}`}>
                  <h4>Origin: <span>{artist?.stations[0]?.location}</span></h4>
                  <h5>Status: <MdVerified /> <span>{artist?.stations[0]?.status}</span></h5>
                  <p>Arrival Date: <span>{artist?.stations[0]?.arrival}</span></p>
                  <p>Left Station: <span>{artist?.stations[0]?.leave}</span></p>
                </li>
                {(artist.stations.length > 2) &&
                  <li className={`station ${stationB}`}>
                    <h4>Station: <span>{artist?.stations[1]?.location}</span></h4>
                    <h5>Status: <MdVerified /> <span>{artist?.stations[1]?.status}</span></h5>
                    <p>Arrival Date: <span>{artist?.stations[1]?.arrival}</span></p>
                    <p>Left Station: <span>{artist?.stations[1]?.leave}</span></p>
                  </li>}
                {(artist.stations.length > 3) &&
                  <li className={`station ${stationC}`}>
                    <h4>Station: <span>{artist?.stations[2]?.location}</span></h4>
                    <h5>Status: <MdVerified /> <span>{artist?.stations[2]?.status}</span></h5>
                    <p>Arrival Date: <span>{artist?.stations[2]?.arrival}</span></p>
                    <p>Left Station: <span>{artist?.stations[2]?.leave}</span></p>
                  </li>
                }
                {(artist.stations.length > 4) &&
                  <li className={`station ${stationD}`}>
                    <h4>Station: <span>{artist?.stations[3]?.location}</span></h4>
                    <h5>Status: <MdVerified /> <span>{artist?.stations[3]?.status}</span></h5>
                    <p>Arrival Date: <span>{artist?.stations[3]?.arrival}</span></p>
                    <p>Left Station: <span>{artist?.stations[3]?.leave}</span></p>
                  </li>
                }
                {(artist.stations.length > 5) &&
                  <li className={`station ${stationE}`}>
                    <h4>Station: <span>{artist?.stations[4]?.location}</span></h4>
                    <h5>Status: <MdVerified /> <span>{artist?.stations[4]?.status}</span></h5>
                    <p>Arrival Date: <span>{artist?.stations[4]?.arrival}</span></p>
                    <p>Left Station: <span>{artist?.stations[4]?.leave}</span></p>
                  </li>
                }
                <li className={`destination ${stationF}`}>
                  <h4>Destination: <span>{last?.location}</span></h4>
                  <h5>Status: <GoUnverified /> <span>{last?.status}</span></h5>
                  <p>Arrival Date: <BsThreeDots /><span>{last?.arrival}</span></p>
                  <p>Date Collected: <span>{last?.leave}</span></p>
                </li>
              </ul>

              <div className="parcel-info">
                <h1> Cargo Details </h1>
                <div className="sender">
                  <h2> Sender's Details</h2>
                  <h3>Full Name: <span>{artist?.sender?.name}</span></h3>
                  <h3>Email: <span>{artist?.sender?.email}</span></h3>
                  <h3>Additional Contact: <span>{artist?.sender?.telephone}</span></h3>
                </div>

                <div className="pickup">
                  <h2> Reciever's Details</h2>
                  <h3>Full Name: <span>{artist?.picker?.name}</span></h3>
                  <h3>Email: <span>{artist?.picker?.email}</span></h3>
                  <h3>Additional Contact: <span>{artist?.picker?.telephone}</span></h3>
                </div>

                <div className="deliv">
                  <h2> Delivery Details</h2>
                  <h3>Carrier: <span>{artist?.carrier}</span></h3>
                  <h3>Freight: <span>{artist?.medium}</span></h3>
                  <h3>Cargo Type: <span>{artist?.cargoType}</span></h3>
                  <h3>Created Date: <span>{artist?.createdDate}</span></h3>
                  <h3>Coming From: <span>{artist?.origin}</span></h3>
                  <h3>Destination: <span>{artist?.destination}</span></h3>
                </div>

                <div className="specification">
                  <h2>Specifications</h2>
                  <h3>Quantity: <span>{artist?.quantity}</span></h3>
                  <h3>Net Weight(lbs): <span>{artist?.weight}</span></h3>
                  <h3>Width: <span>{artist?.width}</span></h3>
                  <h3>Height: <span>{artist?.height}</span></h3>
                  <h3>Length: <span>{artist?.length}</span></h3>
                  <p>More Info: <span>{artist?.info}</span></p>
                </div>

                <div className="pricing">
                  <h2> More Details</h2>
                  <h3>Shipping Fee: <span>{artist?.cost}</span></h3>
                  <h3>Custom: <span>{artist?.customFee}</span></h3>
                  <h3>Status: <span>{artist?.status}</span></h3>
                </div>

              </div>
              <h4> <MdNearbyError />You can't make changes to your shipment online nor can you claim your goods online. <span>Please visit any of our stations or contact our support team</span></h4>
            </div>
          }
          <div className="error-box">
            <h2> <span> Enter Tracking Number </span></h2>
            <form onSubmit={handleSubmit} className="filler">
              <input type="text" name='bol' id='bol' placeholder='Enter BOL' onChange={handleChange} required />
              <button type='submit'>Track</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard