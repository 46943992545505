import React from 'react'
import { Link } from 'react-router-dom'

import '../styles/solution.css'

const Solutions = () => {
  return (
    <>
    <div className="solutions">
      <div className="solutions-cont">
        <ul className="transport">
          <h3>Transport Solutions</h3>
          <li><Link to={`/`}>Air Freight</Link></li>
          <li><Link to={`/`}>Sea Freight</Link></li>
          <li><Link to={`/`}>Groupage</Link></li>
          <li><Link to={`/`}>Land Transport</Link></li>
        </ul>
        <ul className="industry">
          <h3>Industry Solutions</h3>
          <li><Link to={`/`}>Chemicals</Link></li>
          <li><Link to={`/`}>DIY</Link></li>
          <li><Link to={`/`}>Automotive</Link></li>
          <li><Link to={`/`}>Consumer Goods</Link></li>
          <li><Link to={`/`}>Life Science</Link></li>
        </ul>
        <ul className="warehousing">
          <h3>Warehousing</h3>
          <li><Link to={`/`}>Contract Logistics</Link></li>
          <li><Link to={`/`}>Value Added Services</Link></li>
          <li><Link to={`/`}>Warehouse Logistics</Link></li>
          <li><Link to={`/`}>Consultancy</Link></li>
        </ul>
        <ul className="special-services">
          <h3>Special Services</h3>
          <li><Link to={`/`}>Custom Services</Link></li>
          <li><Link to={`/`}>Foreign Trade</Link></li>
          <li><Link to={`/`}>Compliance</Link></li>
        </ul>
        <ul className="resources">
          <h3>Special Services</h3>
          <li><Link to={`/`}>Partners</Link></li>
          <li><Link to={`/`}>Terms & Conditions</Link></li>
          <li><Link to={`/`}>Custom Documents</Link></li>
          <li><Link to={`/`}>Track Shipment</Link></li>
        </ul>
      </div>
    </div>
    </>
  )
}

export default Solutions