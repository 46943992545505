import React from 'react'
import { Link } from "react-router-dom";

import '../styles/banner.css'
import TrackForm from '../../others/template/TrackForm';

const Banners = () => {
	return (
		<div className="banned">
			<div className="banned-container">
				<ul>
					<li className='beforeWas'>
						<TrackForm />
						<h2>Ocean</h2>
						<h4>Quality Without Question</h4>
						<p>With a global network and advanced logistics solutions, our Ocean freight service provide</p>
						<div className="banned-links">
							<Link to={`/ocean-freight`}>Ocean Freight</Link>
							<Link to={`/get-quote`}>Get Quote</Link>
						</div>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Banners