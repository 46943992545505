import axios from 'axios';

const API = axios.create({ baseURL: 'https://twosecscourier.onrender.com' });

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')){
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
})

export const fetchArtists = () => API.get('/artist');
export const fetchArtist = (id) => API.get(`/artist/${id}`);
export const createArtist = (artistDets) => API.post('/artist/new-artist', artistDets);
export const updateArtist = (artistID, artistDets) => API.patch(`/artist/${artistID}/update`, artistDets);

export const msg = (msgDets, userID) => API.post(`/auth/sendMsg/${userID}`, {msgDets});
export const updateMsgCounterSupport = (current, userID) => API.patch(`/auth/${userID}/updateMsgCounterSupport`, {current});
