import React from 'react'
import { ColorRing } from 'react-loader-spinner'

import '../styles/loading.css'

const Loaded = () => {
  return (
    <>
      <div className="loading mh1">
        <div className="loading-cont">
          <ColorRing
            visible={true}
            height="100"
            width="100"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
          />
        </div>
      </div>
    </>
  )
}

export default Loaded