import React from 'react'
import { Link } from "react-router-dom";

import { GiCargoShip } from "react-icons/gi";
import { GoLocation } from "react-icons/go";
import { RiCustomerService2Fill } from "react-icons/ri";

import '../styles/eta.css'
const ETA = () => {
  return (
    <>
      <div className="eta">
        <div className="eta-cont">
          <div className="foot-nav">
        <div className="logo"><Link to="/"><h2><GiCargoShip /></h2><h4>QShip <span>USA</span></h4></Link><h5>Courier & Cargo</h5></div>
          </div>
          <div className="etas">
            <RiCustomerService2Fill />
            <ul>
              <h4>Contact Us</h4>
              <li><p>24/7 Support</p></li>
              <li><a href="sms:/+14092839102"><p>{`+1 (409) 283-9102`}</p></a></li>
              <a href="mailto:support@qshipusa.site">support@qshipusa.site</a>
            </ul>
          </div>
          <div className="etas">
            <GoLocation />
            <ul>
              <h4>Location</h4>
              <li><p>USA, NY 11205</p></li>
              <li><p>680 Bedford Ave, Brooklyn</p></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default ETA