import React from 'react'
import { Link } from 'react-router-dom'

import '../styles/brochure.css'

import {AiOutlineFilePdf} from 'react-icons/ai'
import {FiLink} from 'react-icons/fi'
import {MdOutlineDiversity3} from 'react-icons/md'

const Brochure = () => {
  return (
    <>
    <div className="brochures">
      <div className="brochure-cont">
        <div className="brochure-card">
          <h3>Brochure</h3>
          <p>Working from home meant we could vary snack and coffee breaks.</p>
          <Link to={`/`}>Download PDF</Link>
        </div>
        <div className="call-lines">
          <div className="svg"><MdOutlineDiversity3 /></div>
          <h3>Let's help you!</h3>
          <h5><span>
          United States
          <br />
          Call us: +1 (409) 283-9102
          <br />
          support@qshipusa.site
          <br />
          New York, Bedford Ave, Brooklyn
          </span></h5>
          <Link to={`/`}>or fill our form</Link>
        </div>
        <div className="downloads">
          <h3>Downloads</h3>
          <ul>
            <li>
              <AiOutlineFilePdf />
              <div className="info">
                <Link to={`/`}><h4>Bill of Landing</h4></Link>
                <Link to={`/`}><h5>Download a Fileable PDF</h5></Link>
              </div>
            </li>
            <li>
              <AiOutlineFilePdf />
              <div className="info">
                <Link to={`/`}><h4>Application for Credit</h4></Link>
                <Link to={`/`}><h5>Download a Fileable PDF</h5></Link>
              </div>
            </li>
            <li>
              <FiLink />
              <div className="info">
                <Link to={`/`}><h4>Customs Invoice (CBSA)</h4></Link>
                <Link to={`/`}><h5>How to Download</h5></Link>
              </div>
            </li>
            <li>
              <FiLink />
              <div className="info">
                <Link to={`/`}><h4>Commercial Invoice (CI)</h4></Link>
                <Link to={`/`}><h5>How to Download</h5></Link>
              </div>
            </li>
            <li>
              <AiOutlineFilePdf />
              <div className="info">
                <Link to={`/`}><h4>Certificate of Origin</h4></Link>
                <Link to={`/`}><h5>Download a Fileable PDF</h5></Link>
              </div>
            </li>
            <li>
              <AiOutlineFilePdf />
              <div className="info">
                <Link to={`/`}><h4>Freight Claim Form</h4></Link>
                <Link to={`/`}><h5>Download a Fileable PDF</h5></Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </>
  )
}

export default Brochure