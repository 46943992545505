import * as api from '../api/artist';

export const fetchArtists = () => async (dispatch) => {
  try {
      dispatch({ type: 'START_LOADING'});
      const { data } = await api.fetchArtists();

      dispatch({ type: 'FETCH_ARTISTS', payload: data });
      dispatch({ type: 'STOP_LOADING'});
  } catch (error) {
      console.log(error.message);
  }
}

export const fetchArtist = (id, setError) => async (dispatch) => {
  try {
      dispatch({ type: 'START_LOADING'});
      const { data } = await api.fetchArtist(id);

      dispatch({ type: 'FETCH_ARTIST', payload: data });
      setError(false);
      dispatch({ type: 'STOP_LOADING'});
  } catch (error) {
      console.log(error.message);
      setError(true);
      dispatch({ type: 'STOP_LOADING'});
  }
}

export const createArtist = (artistDets, navigate) => async (dispatch) => {
  try {
      dispatch({ type: 'START_LOADING'});
      const { data } = await api.createArtist(artistDets);

      dispatch({ type: 'CREATE_ARTIST', data })
      dispatch({ type: 'STOP_LOADING'});
      navigate('/request-quote/applied')
  } catch (error) {
      console.log(error)
  }
}

export const updateArtist = (artistID, artistDets, navigate ) => async (dispatch) => {
  try {
      dispatch({ type: 'START_LOADING'});
      const { data } = await api.updateArtist(artistID, artistDets);

      navigate(`/artist/${artistID}`)
      dispatch({ type: 'UPDATE', payload: data})
      dispatch({ type: 'STOP_LOADING'});
  } catch (error) {
      console.log(error)
  }
}