import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "../styles/header.css";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { BsFillChatLeftTextFill } from 'react-icons/bs'

import { getAccount } from "../../database/actions/account";


const Header = ({ liveC, setLiveC }) => {
  const [userx, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const { account } = useSelector((state) => state.accounts);  
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = userx?.token

    setUser(JSON.parse(localStorage.getItem('profile')))
  }, [location]);

  useEffect(() => {
    if (userx) {
      dispatch(getAccount(userx?.result?._id));
    }
  }, [dispatch, userx])

  useEffect(() => {
    const pathname = location.pathname.split("/");
    if (pathname.includes('liveChat')) {
      setLiveC(true)
    } else {
      setLiveC(false)
    }
  }, [location.pathname])

  

  useEffect(() => {
    const token = userx?.token

    setUser(JSON.parse(localStorage.getItem('profile')))
  }, [location]);

  return (
    <>
      <DesktopNav />
      <MobileNav />

    </>
  );
};

export default Header;
