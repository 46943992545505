import React from 'react'
import { Link } from 'react-router-dom'

import '../styles/links.css'
const Links = () => {
  return (
    <>
      <div className="footer-links">
        <div className="linked">
          <h3>Services</h3>
          <ul>
            <li>
              <Link to={`/`}>Air Freight</Link>
            </li>
            <li>
              <Link to={`/`}>Sea Freight</Link>
            </li>
            <li>
              <Link to={`/`}>Land Transport</Link>
            </li>
            <li>
              <Link to={`/`}>Groupage</Link>
            </li>
            <li>
              <Link to={`/`}>Consultancy</Link>
            </li>
            <li>
              <Link to={`/`}>Value Added Services</Link>
            </li>
          </ul>
        </div>
        <div className="linked">
          <h3></h3>
          <ul>
            <li>
              <Link ></Link>
            </li>
          </ul>
        </div>
        <div className="linked">
          <h3>Industries</h3>
          <ul>
            <li>
              <Link to={`/`}>Chemicals</Link>
            </li>
            <li>
              <Link to={`/`}>DIY</Link>
            </li>
            <li>
              <Link to={`/`}>Automotive</Link>
            </li>
            <li>
              <Link to={`/`}>Consumer Goods</Link>
            </li>
            <li>
              <Link to={`/`}>Life Science</Link>
            </li>
            <li>
              <Link to={`/`}>Life Science</Link>
            </li>
            <li>
              <Link to={`/`}>Foreign Trade</Link>
            </li>
          </ul>
        </div>
        <div className="linked">
          <h3>Usefull links</h3>
          <ul>
            <li>
              <Link to={`/`}>Track Shipment</Link>
            </li>
            <li>
              <Link to={`/`}>Warehouse Logistics</Link>
            </li>
            <li>
              <Link to={`/`}>Global Agents Network</Link>
            </li>
            <li>
              <Link to={`/`}>FAQ</Link>
            </li>
            <Link to={`/`} className='btm-linkd'>Get A Rate</Link>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Links