import React, { useEffect } from 'react'
import anime from 'animejs'

import './textStroke.css'

const TextStroke = () => {
  useEffect(() => {

    // animate the prescribed nodes in sequence
    const timeline = anime.timeline({
      easing: 'linear',
    });

    // show the x character
    timeline.add({
      targets: '.start',
      strokeDashoffset: [anime.setDashoffset, 0],
      duration: 350,
      delay: anime.stagger(350),
    })

    // show the dashed line
    timeline.add({
      targets: '.in-between',
      strokeDashoffset: [0, anime.setDashoffset],
      duration: 1500,
    })

    // ping, show the goalpost icon
    timeline.add({
      targets: '.end',
      rotate: [20, 0],
      opacity: [0, 1],
      easing: 'easeOutBounce',
      duration: 400,
    })
  }, [])
  return (
    <>
      <div className="textStroke mh1">
        <svg viewBox="0 0 100 100">
          <rect width="100" height="100" rx="2" fill="#335581" />
          <g fill="none" stroke="#4DC498" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            {/* <!-- dashed trail --> */}
            <g transform="translate(20 35)">
              <path d="M 0 0 v 25 h 15 l 10 -15 h 10 v 20 h 25" stroke-dasharray="3 6" stroke-dashoffset="2" />
              {/* <!-- animate the dash of a line atop the already dashed line --> */}
              <path class="in-between" stroke="#ff3d00" stroke-width="2.5" d="M 60 30 h -25 v -20 h -10 l -10 15 h -15 v -25" />
            </g>

            {/* <!-- include the x character atop the dashed trail --> */}
            <g transform="translate(20 35)">
              {/* <!-- duplicate the path element describing the x sign to animate the segments in sequence --> */}
              <path class="start" d="M -3 -3 l 6 6" />
              <path class="start" d="M 3 -3 l -6 6" />
            </g>

            {/* <!-- goalpost icon --> */}
            <g transform="translate(80 65)">
              {/* <!-- position the group by rotating the shape from a point on the right side --> */}
              <g transform="translate(40 20)">
                <g class="end">
                  <g transform="translate(-40 -20)">
                    <path d="M 0 0 q 6 -6 6 -10 a 6 6 0 0 0 -12 0 q 0 4 6 10" />
                    <circle cy="-10" r="2" stroke="none" fill="#ff3d00" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  )
}

export default TextStroke