import React from 'react'

import '../styles/difference.css'

import {BsBuildingFillCheck} from 'react-icons/bs'
import {FaClipboardCheck} from 'react-icons/fa'
import {AiOutlineDropbox} from 'react-icons/ai'
const Difference = () => {
  return (
    <>
		<div className="difference">
			<div className="diff-cont">
				<div className="diff-header">
					<h2>What makes us different?</h2>
				</div>
				<ul>
					<li>
						<BsBuildingFillCheck />
						<h5>Sustainable regional carrier</h5>
						<p>From a prosperous provincial carrier into a major international competitor.</p>
					</li>
					<li>
						<FaClipboardCheck />
						<h5>Increased shipment integrity</h5>
						<p>A product integrity tracking shipping label includes a label body for attaching.</p>
					</li>
					<li>
						<AiOutlineDropbox />
						<h5>Transparent package tracking and tracing</h5>
						<p>Tracking and tracing solutions ensure greater transparency in the transport cycle.</p>
					</li>
				</ul>
			</div>
		</div>
		</>
  )
}

export default Difference