import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from "react-router-dom";


const Forml = ({ signin, signup, isSignUp, setIsSignUp }) => {
  const errState = {
    mail: '', pw: ' ', ax: '', err: '', px: '', un: '', rf: ''
  }

  const initialState = {
    email: '',
    userName: '',
    password: '',
    confirmPassword: ''
  }
  const { isLoading } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState(initialState);
  const [errMSG, setErrMSG] = useState(errState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (errMSG.err === `401`) {
      setErrMSG({ ...errMSG, mail: `User don't exist` })
    } else if (errMSG.err === `402`) {
      setErrMSG({ ...errMSG, pw: `incorect password` })
    } else if (errMSG.err === `403`) {
      setErrMSG({ ...errMSG, mail: `Email already exist` })
    } else if (errMSG.err === `404`) {
      setErrMSG({ ...errMSG, px: `Passwod don't match.` })
    } else if (errMSG.err === `405`) {
      setErrMSG({ ...errMSG, un: `Username already taken.` })
    } else if (errMSG.err === `406`) {
      setErrMSG({ ...errMSG, rf: `No User Found` })
    } else if (errMSG.err === `500`) {
      setErrMSG({ ...errMSG, ax: `poor connection!!!` })
    } else {
      setErrMSG({ mail: ``, pw: ``, ax: ``, px: ``, un: `` })
    }
  }, [errMSG.err])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSignUp) {
      dispatch(signup(formData, navigate, setErrMSG));
    } else if ((!isSignUp)) {
      dispatch(signin(formData, navigate, setErrMSG));
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const switchMode = () => {
    setIsSignUp((prevIsSignUp) => !prevIsSignUp);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className='filler'>
        <h6>{errMSG.ax}</h6>
        {
          isSignUp && (
            <>
                <input type="text" name='userName' id='userName' onChange={handleChange} placeholder='Username' required />
                <h6> {errMSG.un} </h6>
            </>
          )
        }
          <input type="email" name="email" id="email" onChange={handleChange} placeholder='email' required />
          <h6> {errMSG.mail} </h6>

          <input type="password" name="password" id="password" onChange={handleChange} placeholder='password' required />
          <h6>{errMSG.pw}</h6>
        {
          isSignUp &&
          <>
            <input type="password" name="confirmPassword" id="confirmPassword" placeholder='confirm password' onChange={handleChange} required />
            <h6> {errMSG.px} </h6>
          </>
        }
        {
          isSignUp && (
            <div className='tnc'>
              <input type="checkbox" name="tnc" id="tnc" required />
              <label htmlFor="tnc" className='tnc'> I agree with <Link to='/auth'> Privacy & Policy</Link>,<Link to='/auth'>Terms & Condition</Link></label>
            </div>
          )
        }
        <button type="submit">{isLoading ? "Logging in..." : (isSignUp ? 'Sign Up' : 'Sign In')} </button>
        <p onClick={switchMode}> {
          isSignUp ? 'Have an account? Sign In' : "Don't Have an account? Sign Up"
        }</p>
      </form>
    </>
  )
}

export default Forml