import React from 'react'
import RequestQuote from '../../home/templates/RequestQuote'

const Request = () => {
  return (
    <>
    <div className="request">
      <div className="headt">
        <h2>Request A Free Quote</h2>
      </div>
        <RequestQuote />
    </div>
    </>
  )
}

export default Request