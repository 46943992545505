import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

import { createArtist } from '../../database/actions/artist'
const RequestQuote = () => {
  
  // const { isCreatingFile } = useSelector((state) => state.files);
  const initialState = {
    medium: '',
    sender: '', s_email: '', s_telephone: '',
    reciever: '', r_email: '',
    cargoType: '', origin: '', destination: '',
    quantity: '', weight: '', width: '', height: '', length: '', info: ''
  }
  
  const [formData, setFormData] = useState(initialState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const artistDets = {
    "sender": {
      "name": `${formData.sender}`,
      "email": `${formData.s_email}`,
      "telephone": `${formData.s_telephone}`,
    },
    "picker": {
      "name": `${formData.reciever}`,
      "email": `${formData.r_email}`,
    },
    "cargoType": `${formData.cargoType}`,
    "origin": `${formData.origin}`,
    "destination": `${formData.destination}`,
    "quantity": `${formData.quantity}`,
    "weight": `${formData.weight}`,
    "width": `${formData.width}`,
    "height": `${formData.height}`,
    "length": `${formData.length}`,
    "info": `${formData.info}`,
    }

    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  
    const handleSubmit = (e) => {
      e.preventDefault();
      dispatch(createArtist(artistDets, navigate))
    }
  
    // if (isCreatingFile) {
    //   return (
    //     <h1>Loading</h1>
    //   )
    // }
  
  return (
    <>
      <div className="requestQuote">
        <div className="reqQuo-cont">
          <form onSubmit={handleSubmit} className='filler'>
            <select name="medium" id="medium" onChange={handleChange}>
              <option value="Ocean Freight">Ocean Freight</option>
              <option value="Air Freight">Air Freight</option>
              <option value="Land Transport">Land Transport</option>
            </select>
              <input type="text" name="sender" id="sender" placeholder='Full Name' onChange={handleChange} required />
              <input type="email" name="s_email" id="s_email" placeholder='Sender`s Email' onChange={handleChange} required />
              <input type="number" name="s_telephone" id="s_telephone" placeholder='Sender`s Phone' onChange={handleChange} required />
              <input type="text" name="reciever" id="reciever" placeholder='Name of Reciever' onChange={handleChange} required />
              <input type="email" name="r_email" id="r_email" placeholder='Picker`s Email' onChange={handleChange} required />
              <input type="text" name="cargoType" id="cargoType" placeholder='Cargo Type' onChange={handleChange} required />
              <input type="text" name="origin" id="origin" placeholder='Country of Origin' onChange={handleChange} required />
              <input type="text" name="destination" id="destination" placeholder='Destination' onChange={handleChange} required />
              <input type="number" name="quantity" id="quantity" placeholder='Quantity' onChange={handleChange} required />
              <input type="number" name="weight" id="weight" placeholder='Weight' onChange={handleChange} required />
              <input type="number" name="width" id="width"  placeholder='Width' onChange={handleChange} required />
              <input type="number" name="height" id="height" placeholder='Height' onChange={handleChange} required />
              <input type="number" name="length" id="length" placeholder='Length' onChange={handleChange} required />
              <input type="text" name="info" id="info" placeholder='Additional Info:' onChange={handleChange} maxLength={200} required />
            <button type="submit">Request A Quote</button>
          </form>
          <div className="side-foot">
            <h3>We will contact you soon!</h3>
            <p>As one of the world's leading supply chain management companies.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestQuote