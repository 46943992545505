import React from 'react'

import '../styles/news.css'
import { Link } from 'react-router-dom'
const News = () => {
  return (
    <>
      <div className="news-letter">
        <div className="newsletter-cont">
          <div className="newsletter-header">
            <h2 className='afted'>News <br />& Updates</h2>
          </div>
          <div className="newsletter-sect">
            <h4>Subscribe to our newsletter:</h4>
            <p>E-newsletter focused on the air transport industry</p>
          </div>
          <div className="news-sect">
            <form action="#" className='filler'>
              <input type="email" name="email" id="email" placeholder="Email Address" required />
              <button type="submit">Sign Me Up</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default News