import React , { useState} from 'react'
import { useNavigate } from 'react-router-dom'

import '../styles/trackform.css'

const TrackForm = () => {
  const initialState = {  bol: ''}
  const [formData, setFormData] = useState(initialState);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    navigate(`/track-shipment/${formData.bol}`)
  }
  return (
    <>
      <div className="trackform">
        <form onSubmit={handleSubmit} className='filler'>
          <input type="text" name="bol" id="bol" placeholder='Enter Tracking Number eg. 123a4bc' onChange={handleChange} required />
          <button type="submit">Track & Trace</button>
        </form>
      </div>
    </>
  )
}

export default TrackForm