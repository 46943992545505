import React from 'react'

import '../styles/remarks.css'

import {GiCargoCrate} from 'react-icons/gi'
import {IoMdGlobe} from 'react-icons/io'
import {FaPeopleArrows, FaPeopleCarry} from 'react-icons/fa'
const Remarks = () => {
  return (
    <>
    <div className="remarks">
        <div className="remark-cont">
            <h2 className='afted'> Our clients,<br /> and what they say. </h2>
            <ul>
              <li>
                <GiCargoCrate />
                <h3>6,500m</h3>
                <h5>Delivered Packages</h5>
                <p>We strongly support best practice sharing across our operations around the world.</p>
              </li>
              <li>
                <IoMdGlobe />
                <h3>57</h3>
                <h5>Countries Covered</h5>
                <p>As one of the world’s leading supply chain management companies.</p>
              </li>
              <li>
                <FaPeopleArrows />
                <h3>784</h3>
                <h5>Satisfied Clients</h5>
                <p>Provide best-in-class services across its integrated worldwide network.</p>
              </li>
              <li>
                <FaPeopleCarry />
                <h3>4,500m</h3>
                <h5>Tons of Goods</h5>
                <p>Sustainability helps us reduce waste and share the benefits with our customers.</p>
              </li>
            </ul>
        </div>
    </div>
    </>
  )
}

export default Remarks