import * as api from '../api/account';

export const signin = (formData, navigate, setErrMSG) => async (dispatch) => {
    try {
        dispatch({ type: 'START_LOADING'});
        const { data } = await api.signIn(formData);

        dispatch({ type: 'AUTH', data})
        navigate(`/live-chats`)
        dispatch({ type: 'STOP_LOADING'});
    } catch (error) {
        console.log(error.message)
        setErrMSG({ err: `${error?.response?.status}`})
        dispatch({ type: 'STOP_LOADING'});
    }
}
export const signup = (formData, navigate, setErrMSG) => async (dispatch) => {
    try {
        dispatch({ type: 'START_LOADING'});
        const { data } = await api.signUp(formData);

        dispatch({ type: 'AUTH', data})
        navigate(`/admin/liveChat`)
        dispatch({ type: 'STOP_LOADING'});
    } catch (error) {
        console.log(error.message)
        setErrMSG({ err: `${error?.response?.status}`})
        dispatch({ type: 'STOP_LOADING'});
    }
}