import React, { useState } from 'react'
import { Link } from "react-router-dom";

import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { GiCargoShip } from "react-icons/gi";

const MobileNav = () => {
  const [menu, setMenu] = useState(false);
  return (
    <>
      <header className="headed mobile">
        <div className="main-nav">
          <div className="logo"><h2><GiCargoShip /></h2><Link to="/">QShip <span>USA</span></Link></div>
          <div className="nav-switch" onClick={(e) => setMenu(!menu)}>
            {menu ? <h2><AiOutlineClose /></h2> : <h2>< AiOutlineMenu /></h2>}
          </div>
        </div>
        <ul className={'nav-mobile-menu' + (menu ? " activeMobileNav" : " ")}>
          <li><Link to={`/home-modern`}> Home Modern </Link></li>
          <li><Link to={`/track-shipment`}> Track Your Shipment </Link></li>
          <li><Link to={`/company`}> Company </Link></li>
          <li><Link to={`/request-quote`}>Request Free Quote </Link></li>
          <li><Link to={`/our-expertise`}> Our Expertise </Link></li>
          <li><Link to={`/patners`}> Partners </Link></li>
          <li><Link to={`/contact-us`}> Contact US </Link></li>
        </ul>
      </header>
    </>
  )
}

export default MobileNav