import React, {useState} from 'react'
import RequestQuote from './RequestQuote'
import Tracker from './Tracker'

import '../styles/qtt.css'

import modFarm from '../assets/boxes.png'
const QTT = () => {
  const [qTTTab, setQTTTab] = useState(true)
  return (
    <>
      <div className="qtt">
        <div className="qtt-cont">
          <div className={"switch " + (qTTTab ? ' left' : ' right')} >
            <h3 className={' ' + (qTTTab ? ' h4clr' : '')} onClick={() => setQTTTab(true)}>Get a Quote</h3>
            <h3 className={' ' + (qTTTab ? ' ' : ' h4clr')} onClick={() => setQTTTab(false)}>Track & Trace</h3>
          </div>
          <div className="qtt-screen">
            {
              qTTTab ? 
            <div className="get-quote">
              <RequestQuote />
            </div>
            :
              <Tracker />
            }
            <div className="family-man-img"></div>
          </div>
        </div>
        <img src={modFarm} className='modernfam' alt="modern family" />
      </div>
    </>
  )
}

export default QTT