import React from 'react'
import { Link } from 'react-router-dom'

import '../styles/applied.css'

import {MdVerified} from 'react-icons/md'

const ApplicationSent = () => {
  return (
    <>
    <div className="applied mh1">
      <div className="applied-cont">
        <div className="applied-info">
          <MdVerified />
          <h2>Application Sent</h2>
        </div>
          <p>We will contact you with the email provided</p>
          <Link to={`/`}> Continue </Link>
      </div>
    </div>
    </>
  )
}

export default ApplicationSent