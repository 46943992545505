export default (state = { isLoading: true, artists: [] }, action) => {
    switch (action.type) {
        case 'START_LOADING':
            return { ...state, isLoading: true };
        case 'STOP_LOADING':
            return { ...state, isLoading: false };
        case 'FETCH_ARTISTS':
            return { ...state, artists: action.payload };
        case 'FETCH_ARTIST':
            return { ...state, artist: action.payload };
        case 'CREATE_ARTIST':
            return { ...state, artists: [...state.artists, action.payload] };
        case 'UPDATE':
            return {
                ...state,
                artists: state.artists.map((artist) => {
                    if (artist._id === action.payload._id) {
                        return artist.payload;
                    }
                    return artist
                })
            };
        case 'DELETE' :
        return {
            ...state,
            artists: state.artists.filter((artist) => artist._id !== action.payload )
        }
        default:
            return state;
    }
}