import React, { useState } from 'react';

import '../styles/auth.css'

import { signin, signup } from '../../database/actions/auth';
import Forml from './Forml';

const Auth = () => {
	const [isSignUp, setIsSignUp] = useState(false);

	return (
		<>
			<div className="auth" >
				<div className="auth-container">
					<div className="login">
						<Forml signin={signin} signup={signup} setIsSignUp={setIsSignUp} isSignUp={isSignUp} />
					</div>
				</div>
			</div>
		</>
	)
}

export default Auth