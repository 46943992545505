import React, { useEffect } from "react";

import '../styles/home.css'
import Banners from "./Banners";
import About from "./About";
import Trust from "./Trust";
import QTT from "./QTT";
import Remarks from "./Remarks";
import Brands from "./Brands";
import Difference from "./Difference";
import News from "./News";
import Solutions from "./Solutions";
import Brochure from "./Brochure";
const Home = () => {
  useEffect(() => {

  }, []);
  return (
    <>
      <Banners />
      <div className="home">
        <div className="home-main">
          <About />
          <Trust />
          <QTT />
          <Remarks />
          <Brands />
          <Difference />
          <News />
        </div>
        <div className="home-side">
          <Solutions />
          <Brochure />
        </div>
      </div>
    </>
  )
}

export default Home